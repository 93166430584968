import Tooltip from '@totallymoney/ui/icons/Tooltip'
import styled from 'styled-components'

const StyledTooltipButton = styled.button`
    display: inline-flex;
    align-items: center;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
`

interface TooltipButtonProps {
    color?: string
    onPress: () => void
    size?: number
    title: string
}

const TooltipButton = ({
    color,
    size = 20,
    onPress,
    title,
    ...props
}: TooltipButtonProps) => {
    return (
        <StyledTooltipButton {...props} onClick={() => onPress()}>
            <Tooltip size={size} color={color} title={title} />
        </StyledTooltipButton>
    )
}

export default TooltipButton
