import { captureException } from '@sentry/nextjs'
import { useEffect, useMemo, useState } from 'react'

import getCategoryByCategoryId from '@utils/getCategoryByCategoryId'
import {
    LocalStorageItem,
    getLocalStorageItem,
    removeLocalStorageItem,
    setLocalStorageItem,
} from '@utils/localStorage'
import {
    SessionStorageItem,
    getSessionStorageItem,
    setSessionStorageItem,
} from '@utils/sessionStorage'

import {
    CreditCardProductCategory,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'

interface PreviousCreditCardOffers {
    customerId: string
    previousCreditCardOffers: number
}

interface PreviousCreditCardProductCodes {
    customerId: string
    previousCreditCardProductCodes: string[]
}

export const calculateNumberOfNewOffersFromLegacyStoredValue = (
    productCodes: string[],
    previousCreditCardOffersCount: number | null
): string[] => {
    const previousTotalCreditCards = Number(previousCreditCardOffersCount)
    if (productCodes.length > previousTotalCreditCards) {
        const fakeProductCodesArray = new Array(
            productCodes.length - previousTotalCreditCards
        ).fill('card')
        return fakeProductCodesArray
    }
    return []
}

export const getDifferenceInCardProductCodesForAllCards = (
    productCodes: string[],
    previousCreditCardOffers: string[]
) => {
    return productCodes.filter(productCode => {
        const isInPreviouslySeenOffers =
            previousCreditCardOffers.includes(productCode)
        return !isInPreviouslySeenOffers
    })
}

export const getProductCodeArrayOfNewCards = (
    customerId: string,
    productCodes: string[],
    localStorageCardOffers: string | null,
    localStorageCardProductCodes: string | null
): {
    diff: string[]
    productCodes?: string[]
} => {
    if (
        localStorageCardProductCodes === null &&
        localStorageCardOffers !== null
    ) {
        const parsedLocalStorageCardOffers = JSON.parse(
            localStorageCardOffers
        ) as PreviousCreditCardOffers
        if (parsedLocalStorageCardOffers?.customerId !== customerId) {
            return { diff: [] }
        }
        const diff = calculateNumberOfNewOffersFromLegacyStoredValue(
            productCodes,
            parsedLocalStorageCardOffers.previousCreditCardOffers
        )
        return { diff, productCodes }
    }

    const parsedLocalStorageCardProductCodes = JSON.parse(
        localStorageCardProductCodes!
    ) as PreviousCreditCardProductCodes
    if (parsedLocalStorageCardProductCodes?.customerId !== customerId) {
        return { diff: [] }
    }
    if (
        parsedLocalStorageCardProductCodes.previousCreditCardProductCodes
            .length > 0
    ) {
        const diff = getDifferenceInCardProductCodesForAllCards(
            productCodes,
            parsedLocalStorageCardProductCodes.previousCreditCardProductCodes
        )
        return { diff, productCodes }
    }

    return { diff: [], productCodes }
}

export const getDifferenceInCardProductCodesForSelectedCategory = (
    data: ScoredCreditCardProductsCategory[],
    category: CreditCardProductCategory,
    allCardsDifference: string[]
): string[] => {
    const selectedCategoryCards =
        getCategoryByCategoryId(data, category)?.creditCards ?? []
    if (selectedCategoryCards.length === 0) {
        return []
    }
    if (allCardsDifference != null && selectedCategoryCards.length > 0) {
        const productCodes = selectedCategoryCards.map(card => card.productCode)
        return productCodes.filter(productCode =>
            allCardsDifference.includes(productCode)
        )
    }
    return []
}

const useGetDifferenceInNewCreditCardsOffers = (
    data: ScoredCreditCardProductsCategory[] = [],
    category: CreditCardProductCategory = CreditCardProductCategory.BestCards
): string[] => {
    const { appQueryState } = useAppQueryState()

    const { creditCards } = getCategoryByCategoryId(data, category) ?? {
        creditCards: [],
    }

    const differenceInNewCreditCardsOffers: {
        diff: string[]
        productCodes?: string[]
    } | null = useMemo(() => {
        try {
            const sessionStorageCardsDiff = getSessionStorageItem(
                SessionStorageItem.newCardProductCodes
            )
            if (sessionStorageCardsDiff == null) {
                if (creditCards === undefined || creditCards.length === 0) {
                    return null
                }
                const localStorageCardOffers = getLocalStorageItem(
                    LocalStorageItem.previousCreditCardOffers
                )
                const localStorageCardProductCodes = getLocalStorageItem(
                    LocalStorageItem.previousCreditCardProductCodes
                )
                const productCodes = creditCards.map(card => card.productCode)

                if (
                    localStorageCardProductCodes == null &&
                    localStorageCardOffers == null
                ) {
                    return { diff: [], productCodes }
                }

                return getProductCodeArrayOfNewCards(
                    appQueryState.customerId!,
                    productCodes,
                    localStorageCardOffers,
                    localStorageCardProductCodes
                )
            }
            const parsedSessionStorageCardsDiff = JSON.parse(
                sessionStorageCardsDiff
            )
            if (category === CreditCardProductCategory.BestCards) {
                return { diff: parsedSessionStorageCardsDiff }
            } else {
                return {
                    diff: getDifferenceInCardProductCodesForSelectedCategory(
                        data,
                        category,
                        parsedSessionStorageCardsDiff
                    ),
                }
            }
        } catch (err) {
            captureException(err, { tags: { newOffers: true } })
            return null
        }
    }, [creditCards, appQueryState.customerId, category])

    useEffect(() => {
        if (differenceInNewCreditCardsOffers?.productCodes != null) {
            setLocalStorageItem(
                LocalStorageItem.previousCreditCardProductCodes,
                JSON.stringify({
                    customerId: appQueryState.customerId!,
                    previousCreditCardProductCodes:
                        differenceInNewCreditCardsOffers.productCodes,
                } satisfies PreviousCreditCardProductCodes)
            )
            removeLocalStorageItem(LocalStorageItem.previousCreditCardOffers)
            setSessionStorageItem(
                SessionStorageItem.newCardProductCodes,
                JSON.stringify(differenceInNewCreditCardsOffers.diff)
            )
        }
    }, [differenceInNewCreditCardsOffers?.productCodes])

    return differenceInNewCreditCardsOffers?.diff ?? []
}

export default useGetDifferenceInNewCreditCardsOffers
