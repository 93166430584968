import Box from '@totallymoney/ui/components/Box'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'

import { getRepresentativeExampleTextForCard } from '@utils/getRepresentativeExampleTextForCard'

import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

import TooltipButton from '@components/TooltipButton'

const RepresentativeExample = ({
    item,
    onTooltipPress,
}: {
    item: ScoredCreditCardProduct
    onTooltipPress: () => void
}) => (
    <Box display="inline-block">
        <Stack
            as="span"
            verticalAlign="middle"
            space="space10"
            mr="space10"
            direction="row"
        >
            <Text weight="bold">Representative Example</Text>{' '}
            <TooltipButton
                data-testid="representative-example-tooltip"
                title={`Tooltip icon for representative example - ${item.productCode}`}
                aria-label="What is Representative Example?"
                color={theme.grey100}
                onPress={onTooltipPress}
            />
        </Stack>

        <Text variant="100" color="light">
            {getRepresentativeExampleTextForCard(item)}
        </Text>
    </Box>
)

export default RepresentativeExample
