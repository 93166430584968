import Box from '@totallymoney/ui/components/Box'
import Grid from '@totallymoney/ui/components/Grid'
import Heading from '@totallymoney/ui/components/Heading'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'
import Image from 'next/image'

import errorPersonHoldingPhone from '../../public/images/error-person-holding-phone.png'

interface ErrorPageProps {
    children?: React.ReactElement
    subtitle?: string
    title?: string
}

const ErrorPage = ({
    title = 'Oh! This doesn’t look right',
    subtitle = 'Sorry, the page you requested hit an error. Hit the browser back button or try one of the links below.',
    children,
}: ErrorPageProps) => {
    return (
        <Box
            bg={theme.productNeutral100}
            p={[0, 'space20', 'space60']}
            pb="space60"
        >
            <Grid>
                <Box
                    gridColumn={['1/13', '1/6']}
                    display="flex"
                    justifyContent="center"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        pr="space30"
                        width={['300px', '100%']}
                    >
                        <Image
                            src={errorPersonHoldingPhone}
                            alt="A person holding a phone with a red cross on the screen"
                            width="384"
                            style={{ maxWidth: '100%', height: 'auto' }}
                            priority={true}
                        />
                    </Box>
                </Box>
                <Box gridColumn={['1/13', '6/13', '6/11']} display="flex">
                    <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box mb="space20">
                            <Heading variant="140">{title}</Heading>
                        </Box>
                        <Text variant="120">{subtitle}</Text>
                        {children}
                    </Box>
                </Box>
            </Grid>
        </Box>
    )
}

export default ErrorPage
