import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'
import { ReactElement, useEffect, useRef, useState } from 'react'

import { eligibilityForCreditProductFaqUrl } from '@constants/helpLinks'

import {
    PercentageScoreContainer,
    ScoreBarInner,
} from './EligibilityIndicator.styled'
import { getEligibilityVariantOptions } from './utils/getEligibilityVariantOptions'

const EligibilityIndicator = ({ score }: { score?: number }): ReactElement => {
    const variantOptions = getEligibilityVariantOptions(score)
    const [transition, setTranstion] = useState(0)

    const refContainer = useRef<HTMLAnchorElement | null>(null)

    const checkVisible = () => {
        if (refContainer.current == null) {
            return
        }

        var rect = refContainer.current.getBoundingClientRect()
        var viewHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight
        )
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
    }

    useEffect(
        function mount() {
            function onScroll() {
                if (refContainer != null && score != null && checkVisible()) {
                    setTranstion(score)
                }
            }
            if (refContainer != null && score != null && checkVisible()) {
                setTranstion(score)
            }
            window.addEventListener('scroll', onScroll)
            return function unMount() {
                window.removeEventListener('scroll', onScroll)
            }
        },
        [score]
    )

    return (
        <PercentageScoreContainer
            ref={refContainer}
            href={eligibilityForCreditProductFaqUrl}
            target="_blank"
        >
            <Box p="space05">
                <Heading textAlign="center" variant="20">
                    {variantOptions.text}
                </Heading>
            </Box>

            <Box
                backgroundColor="grey20"
                height="10px"
                width="100%"
                borderRadius="borderRadius20"
            >
                <ScoreBarInner
                    data-testid="eligibilityIndicatorScoreBar"
                    backgroundColor={variantOptions.indicatorBoxBg}
                    width={`${transition}%`}
                    height="10px"
                    borderTopLeftRadius="borderRadius20"
                    borderBottomLeftRadius="borderRadius20"
                />
            </Box>

            {score != null ? (
                <Box p="space05">
                    <Text
                        weight="bold"
                        textAlign="center"
                        variant="60"
                        style={{ fontFamily: theme.buenosAires }}
                    >
                        {`${score}% chance of acceptance`}
                    </Text>
                </Box>
            ) : null}
        </PercentageScoreContainer>
    )
}

export default EligibilityIndicator
