import Cookies from 'js-cookie'
import React from 'react'

import { getAppConfig } from '@utils/getAppConfig'
import { getMarketingTrackingData } from '@utils/getMarketingTrackingData'

import {
    ApplicationURLTriggerLocation,
    CreditCardProductCategory,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'
import { useBasin } from '@hooks/useBasin'

import { getCardApplicationUrl } from '../utils/getCardApplicationUrl'

interface UseGetCardApplicationUrlOptions {
    category: CreditCardProductCategory
    creditCard: ScoredCreditCardProduct
    creditCardIndex: number
    isBestMatchCard: boolean
    isNewOffer: boolean
    thirdPartyReference: string
    trigger: ApplicationURLTriggerLocation
}

type UseGetCardApplicationUrlReturn = (
    options: UseGetCardApplicationUrlOptions
) => string

const useGetCreditCardApplicationUrl = (): UseGetCardApplicationUrlReturn => {
    const appConfig = getAppConfig()
    const { appQueryState } = useAppQueryState()
    const { ids } = useBasin()
    const trackingData = getMarketingTrackingData(appQueryState)

    const memoizedGetCreditCardApplicationUrl = React.useCallback(
        ({
            category,
            creditCard,
            isBestMatchCard,
            thirdPartyReference,
            creditCardIndex,
            trigger,
            isNewOffer,
        }: UseGetCardApplicationUrlOptions) => {
            return getCardApplicationUrl({
                clickSource: appConfig.CLICK_SOURCE_WHITELABEL,
                client: appConfig.NAME_WHITELABEL,
                productCode: creditCard.productCode,
                status: creditCard.status,
                score: creditCard.score,
                category: category,
                prepopulationToken: creditCard.prepopulationToken,
                provider: creditCard.provider,
                productIndex: creditCardIndex,
                isBestMatchCard: isBestMatchCard,
                featured: creditCard.sponsored,
                visitGuid: ids?.sessionId!,
                dailyId: ids?.dailyId!,
                permanentId: ids?.permanentId!,
                customerId: appQueryState.customerId!,
                scoreId: appQueryState.scoreId!,
                isKnownCustomer: appQueryState.isKnownCustomer!,
                thirdPartyReference: thirdPartyReference,
                guaranteedApr: creditCard.guaranteedRate?.guaranteedAPR,
                guaranteedCreditLimit:
                    creditCard.guaranteedRate?.guaranteedCreditLimit,
                trigger: trigger,
                isNewOffer: isNewOffer,

                ...trackingData,
            })
        },
        [
            appQueryState.customerId,
            appQueryState.isKnownCustomer,
            appQueryState.scoreId,
            trackingData,
            ids?.dailyId,
            ids?.permanentId,
            ids?.sessionId,
        ]
    )

    return memoizedGetCreditCardApplicationUrl
}

export default useGetCreditCardApplicationUrl
